import { classNameGenerator } from "@/utils";
import styles from "./Services.module.scss"
import ServiceCard from "./ServiceCard";
import { useAppSelector } from "@/hooks";

const Services = () => {
    const cls = classNameGenerator(styles)
    const { data } = useAppSelector((state) => state.healthProgramV1);

    if(!data?.services) {
        return <></>
    }
    const {services} = data

    return (
        <section id="service-card" className={cls("services")}>
            <div className={cls("contianer")}>
                <ServiceCard {...services[0]}/>
                <ServiceCard {...services[1]}/>
            </div>
        </section>
    )
}

export default Services;