import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import * as actions from "./Actions";

const initialState = {
  isLoading: false,
  error: "",
  success: false,
  data: {},
  questionnaireData: {}
};

const proteinometerPageSlice = createSlice({
  name: "proteinometer",
  initialState,
  reducers: {
    setQuestionnaireAnswer: (state, { payload }) => {
      return {
        ...state,
        questionnaireData: {
          ...payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      //HYDRATE
      .addCase(HYDRATE, (state, { payload }) => ({
        ...state,
        ...payload.proteinometer,
      }))
      .addCase(actions.getProteinometerPage.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(
        actions.getProteinometerPage.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            success: true,
            data: payload,
          };
        }
      )
      .addCase(
        actions.getProteinometerPage.rejected,
        (state, { payload }) => ({
          ...state,
          isLoading: false,
          error: payload || "",
        })
      );
  },
});

export { getProteinometerPage } from "./Actions";

export const {
  setQuestionnaireAnswer
} = proteinometerPageSlice.actions;

export default proteinometerPageSlice.reducer;
