import { classNameGenerator } from "@/utils"
import styles from "./CommunityCard.module.scss"
import Typography from "@/foundation/Typography/V2"
import { useAppSelector } from "@/hooks"
import { Button, Image, SVGSprite } from "@/components/Atoms"
import { useRouter } from "next/router"

const CommunityCard = () => {
    const router = useRouter()
    const cls = classNameGenerator(styles)
    const { data } = useAppSelector((state) => state.healthProgramV1);

    if (!data?.communityCard) {
        return <></>
    }
    const { banner, bannerMobile, title, description, ctaText } = data?.communityCard

    return (
        <section className={cls("communityCard")}>
            <div className={cls("container")}>
                <div className={cls("content")}>
                    <Typography variant="heading3" className={cls("title")}>
                        {title}
                    </Typography>
                    <Typography variant="paragraph1" className={cls("description")}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                    <Button
                        className={cls("actionButton")}
                        onClick={() =>
                            router.push("/joincommunity/?utm_source=shyft_website&utm_medium=header-button&adset_name=top_navbar")
                        }
                    >
                        {ctaText}
                        <SVGSprite
                            icon="icon-arrow-right-filled"
                            className={cls("icon")}
                        />
                    </Button>
                </div>
                <div className={cls("banner")}>
                    <Image
                        alt={title}
                        src={banner}
                        className={cls("cardImage")}
                    />
                    <Image
                        alt={title}
                        src={bannerMobile || banner}
                        className={cls("mWebCardImage")}
                    />
                </div>
            </div>
        </section>
    )
}

export default CommunityCard