const data = {
    "status": true,
    "data": {
        "logo": "https://app.mindhouse.com//static/uploads/group_1xxd0NJ.png",
        "heroSection": [
            {
                "bgImage": "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/hompagebanner1-c3ae9c06bd3a41fb9fd99fa7bc5e7516.png",
                "title": "Live healthy,<br/><span>Longer</span>",
                "description": "At Shyft we are committed to creating products and services of the highest quality leading to maximum impact. Developed by global experts and tested by real customers."  
            }
        ],
        "faqs": {
            "title": "Frequently Asked Questions",
            "faqs": [
                {
                    "question": "What is Shyft?",
                    "sub_title": "",
                    "answer": "At Shyft, we create the highest quality products and services for better health. Our health products are available across India and our online services (yoga classes and nutrition consultations) can be accessed globally"
                },
                {
                    "question": "What are the products and services offered by Shyft?",
                    "sub_title": "",
                    "answer": "We offer online Yoga classes and Nutrition consultations. We have recently launched our range of health products with Plant Protein Isolate in Natural Cocoa as our first product. Our health products are crafted with a focus on quality and simplicity. We use only all-natural ingredients and keep our formulations minimal to ensure you get the most benefit."
                },
                {
                    "question": "How do you ensure the quality of your products and services?",
                    "sub_title": "",
                    "answer": "Our products and services are developed by global experts. We work closely with our community and customers for feedback on every product and service before we go live to ensure you only get the best. Our products are also rigorously tested in certified laboratories."
                }
            ]
        },
        "blogs": {
            "description": "On Healthwatch, you will find our curated health and wellness articles",
            "title": "Stay informed and inspired",
            "bottomCta":{
                "text": "Read More Health Tips",
                "icon": "icon-book-open"
            }
        },
        "communityCard": {
            "banner": "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/banner-a7b333a84dbd41c8b1385ea185df616b.png",
            "bannerMobile": "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/bannerMobile-e95163384ea342628f9195af2a89314e.png",
            "title": "Share. Track. Motivate.",
            "description": "Be part of our <span>#ShowUp Community</span> on WhatsApp. Share your goals, track your progress, and motivate each other every day.",
            "ctaText": "Join our WhatsApp Community"
        },
        "customerReview": {
            "title": "Customer reviews",
            "description": "We’re incredibly proud of the impact we’ve had on over 50,000+ lives, and love to hear our members tell us their stories. Hopefully it inspires you as well.",
            "metrics": [
                {
                    "title": "50k<span style=\"font-size: 38px; line-height: 40.71px;font-weight: 500;\">+</span>",
                    "mobileTitle": "50k<span style=\"font-size: 22px; line-height: 25.26px;font-weight: 700;\">+</span>",
                    "description": "users have trusted Shyft"
                },
                {
                    "title": "95<span style=\"font-size: 26px; line-height: 41.54px;font-weight: 800;\">%</span>",
                    "mobileTitle": "95<span style=\"font-size: 14.53px; line-height: 27.55px;font-weight: 600;\">%</span>",
                    "description": "of users report improved fitness"
                },
                {
                    "title": "4.8<span style=\"font-size: 27px; line-height: 45.64px;font-weight: 500;\">★</span>",
                    "mobileTitle": "4.8<span style=\"font-size: 15.53px; line-height: 25.87px;font-weight: 500;\">★</span>",
                    "description": "average app rating on app stores"
                },
            ],
            "ctaText": "Start your journey",
            "reviews": [
                {
                    "text": "In these 6 months of yoga at Shyft, my symptoms like moodswings and anxiety have reduced exponentially. The small batch size ensured personal attention from the trainers and their availability even after class made it easier to resolve all queries.",
                    "image": "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/Drishti.jfif",
                    "info": "Drishti&nbsp;  •&nbsp;  Delhi",
                    "age": "25 yrs."
                },
                {
                    "text": "The instructors are available to help at all times. My PCOS symptoms from the past 8 years have now started to improve with motivation and support. I would suggest women to get their Shyft subscriptions today!",
                    "image": "https://d1mxd7n691o8sz.cloudfront.net/static/uploads/Khyati_1.png",
                    "info": "Khyati&nbsp;  •&nbsp;  Bangalore",
                    "age": "27 yrs."
                },
                {
                    "text": "Absolutely loved the easy mixing-no froth or powder clumps! Felt light with no lingering aftertaste or digestive issues. Light, smooth, and easy to mix!",
                    "image": "https://shop.betheshyft.com/cdn/shop/files/Screenshot_2024-12-19_172820.png?v=1734609513",
                    "info": "Neeraj Jajodia&nbsp;  •&nbsp;  Mumbai",
                    "age": "31 yrs."
                },
                {
                    "text": "Mixes effortlessly with just a few shakes, No digestive issues which is a huge plust Had a pleasant experience with this protein - i usually don't take protein:)",
                    "image": "https://shop.betheshyft.com/cdn/shop/files/WhatsApp_Image_2024-12-18_at_16.27.00_4621aef6_02079793-9a4a-4196-9bb9-7a17fd540b8c.jpg?v=1734609165",
                    "info": "Vidya&nbsp;  •&nbsp;  Pune",
                    "age": "27 yrs."
                }
            ]
        },
        "services": [
            {
                "title": "Move with us",
                "description": "Explore our Yoga classes created by global experts for specific health outcomes. Our classes are online live and interactive!",
                "sideBanner": "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/Yoga_Classes1-676cb8031f3841e386dc3b54c958c66e.png",
                "bgBanner": "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/service1-3e5619978b2b4a439f78edb480d94ff5.png",
                "primaryCta": {
                    "text": "View all plans ",
                    "icon": "icon-right-arrow-simple",
                    "href": "/plans/yoga-classes/",
                    "id": ""
                },
                "secondaryCta": {
                    "text": "Book a trial session ",
                    "icon": "icon-right-arrow-simple-white",
                    "href": "https://tally.so/r/3x97RG",
                    "id": ""
                }
            },
            {
                "title": "Eat right with us",
                "description": "Talk to our Nutritionists to create a nutrition plan tailored to your lifestyle, health needs and goals. Discover the power of eating right!",
                "sideBanner": "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/Yoga_Classes-80cecf41a90143e5a91b1c900286a2d7.png",
                "bgBanner": "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/service2-6f902aa4db034d919261b32f06257cd5.png",
                "primaryCta": {
                    "text": "View all plans ",
                    "icon": "icon-right-arrow-simple",
                    "href": "/plans/nutrition/",
                    "id": ""
                }
            }
        ],
        "stripe": [
            {
                "text": "Impact Focussed",
                "link": ""
            },
            {
                "text": "Rigorously Tested",
                "link": ""
            },
            {
                "text": "Community Driven",
                "link": ""
            },
            {
                "text": "Highest Quality",
                "link": ""
            }
        ],
        "products": {
            "title": "Introducing our Plant Protein Isolate",
            "description": "Maximum Impact, Minimal Ingredients",
            "productDetails": {
                "image": "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/Packaging-760217e90f5a4b0297fd5812e70b5a97.png",
                "proteinometer": {
                    "icon1": "icon-Frame-1171276044",
                    "text": "Confused about protein intake?<br/> Check our proteinometer.",
                    "icon2": "icon-chevron-right1"
                }
            },
            "viewReport": {
                "icon": "icon-Natural",
                "text": "Third party tested."
            },
            "detailsSection": [
                {
                    "cardType": "pre",
                    "itemList": [
                        {
                            "icon": "icon-natural1",
                            "text": "100% Natural Ingredients"
                        },
                        {
                            "icon": "icon-natural2",
                            "text": "No Stabilizers"
                        },
                        {
                            "icon": "icon-natural3",
                            "text": "Zero added Sugar"
                        }
                    ],
                    "ctaText": "Know our Ingredients >"
                },
                {
                    "cardType": "sec",
                    "imageMobile": "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/proteinMobile-1d92a00980b84c86a33cbc0007b4acda.png",
                    "itemList": [
                        {
                            "text": "<span style=\"font-size: 18.83px; line-height: 25.16px;font-weight: 700;\">24g</span> <span style=\"font-size: 14.64px; line-height: 18.37px;font-weight: 700;\">(73%)</span><br/><span>of Protein / scoop</span>",
                            "mobileText": "<span style=\"font-size: 20.86px; line-height: 26.38px;font-weight: 700;\">24g</span> <span style=\"font-size: 15.64px; line-height: 19.79px;font-weight: 700;\">(73%)</span><br/><span style=\"font-size: 13.90px; line-height: 17.38px;font-weight: 500;\">of Protein / scoop</span>"
                        },
                        {
                            "text": "<span style=\"font-size: 18.83px;line-height: 25.16px;font-weight: 700;\">4.2g</span><br/><span>BCAA / scoop</span>",
                            "mobileText": "<span style=\"font-size: 20.83px;line-height: 26.38px;font-weight: 700;\">4.2g</span><br/><span style=\"font-size: 13.90px; line-height: 17.38px;font-weight: 500;\">BCAA / scoop</span>"
                        }
                    ],
                    "ctaText": "Understand protein powder better >"
                },
                {
                    "cardType": "pre",
                    "itemList": [
                        {
                            "icon": "icon-natural1",
                            "text": "<span style=\"font-size: 18.83px;line-height: 25.16px;font-weight: 700;\">85%</span><br/><span>Protein Isolate</span>",
                            "mobileText": "<span style=\"font-size: 20.83px;line-height: 26.38px;font-weight: 700;\">85%</span><br/><span style=\"font-size: 13.90px; line-height: 17.38px;font-weight: 500;\">Protein Isolate</span>",
                        },
                        {
                            "icon": "icon-natural2",
                            "text": "<span style=\"font-size: 18.83px;line-height: 25.16px;font-weight: 700;\">15%</span><br/><span>Everything else</span>",
                            "mobileText": "<span style=\"font-size: 20.83px;line-height: 26.38px;font-weight: 700;\">15%</span><br/><span style=\"font-size: 13.90px; line-height: 17.38px;font-weight: 500;\">Everything else</span>"
                        }
                    ],
                    "ctaText": "Know our Ingredients >"
                },
                {
                    "cardType": "pre",
                    "itemList": [
                        {
                            "icon": "icon-natural4",
                            "text": "Doesn’t smell"
                        },
                        {
                            "icon": "icon-natural6",
                            "text": "Mixes Smoothly"
                        },
                        {
                            "icon": "icon-natural5",
                            "text": "No Digestion issues"
                        }
                    ],
                    "ctaText": "Know more >"
                }
            ],
            "cartDetails": {
                "image": "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/add-to-cart-fde7a5e7be3f490ebbaf362dda247235.png",
                "link": "https://shop.betheshyft.com/products/plant-protein-isolate"
            }
        }
    }
};

export default data;
