import Link from "next/link";
import Image from "@/components/Atoms/Image";
import Typography from "@/foundation/Typography/V2";
import { classNameGenerator } from "@/utils";
import styles from "./BootcampCard.module.scss";

const BootcampCard: React.FC<any> = ({
  title,
  link,
  tag,
  cover_image,
  description,
  reading_time,
  created_date
}) => {
  const cls = classNameGenerator(styles);

  return (
    <a href={link} className={cls("bootcampCard")} target="_blank" rel="noopener noreferrer">
      <div className={cls("thumbnail")}>
        <Image src={cover_image} alt={title} />
      </div>
      <div className={cls("bottom")}>
        <div className={cls("content")}>
          <Typography
            variant="paragraph1"
            className={cls("tag")}
          >
            {tag}
          </Typography>
          <Typography variant="heading3" className={cls("title")}>
            {title}
          </Typography>
          <Typography variant="paragraph1" className={cls("description")}>
            {description}
          </Typography>
        </div>
        <div className={cls("bottomContent")}>
          <span className={cls("text")}>{reading_time} read</span>
          <span className={cls("text")}>|</span>
          <span className={cls("text")}>{created_date}</span>
        </div>
      </div>
    </a>
  );
};

export default BootcampCard;
