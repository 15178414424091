import { useState } from "react";

import Accordion from "@/components/Atoms/AccordionV2";
import Typography from "@/foundation/Typography/V2";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./FaqSection.module.scss";
import FAQ from "@/containers/StaticPages/FAQ/FAQ";

interface FaqSectionProps {
  title: string;
  description?: string;
  faqs: {
    question: string;
    answer: string;
  }[];
  className?: string;
}

const FaqSection: React.FC<FaqSectionProps> = ({
  title,
  description,
  faqs,
  className = ""
}) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const cls = classNameGenerator(styles);
  if (!faqs) {
    return <></>
  }

  return (
    <div id="faqs-section" className={clsx(cls("faqSectionWrapper"), cls(className))}>
      <div className={cls("faqSection")}>
        <div className={cls("topSection")}>
          <Typography variant="heading3" className={cls("title")}>
            {title}
          </Typography>
          {description && (
            <Typography variant="paragraph1" className={cls("description")}>
              {description}
            </Typography>
          )}
        </div>
        <div className={cls("bottomSection")}>
          {faqs?.map((item, index) => {
            return (
              <Accordion
                key={index}
                title={item.question}
                openAccordionIndex={openAccordionIndex}
                setOpenAccordionIndex={setOpenAccordionIndex}
                currentIndex={index}
              >
                <Typography
                  variant="paragraph1"
                  className={cls("accordionAnswer")}
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                />
              </Accordion>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
