import React, { useRef } from "react";
import Slider from "@/components/Atoms/Slider";
import SlickArrowButton from "@/components/Molecules/SlickArrowButton";
import BootcampCard from "./BootcampCard";
import { classNameGenerator } from "@/utils";
import styles from "./BootcampCarousel.module.scss";

const BootcampCarousel: React.FC<any> = ({ bootcamps }) => {
  const sliderRef = useRef(null);

  const settings = {
    autoplay: false,
    autoplaySpeed: 3000,
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: "0px",
        },
      },
    ],
    prevArrow: (
      <SlickArrowButton
        buttonType="prev"
        variant="icon1"
        icon="icon-circle-light-left"
      />
    ),
    nextArrow: (
      <SlickArrowButton
        buttonType="next"
        variant="icon1"
        icon="icon-circle-light-right"
      />
    ),
  };

  const cls = classNameGenerator(styles);

  return (
    <section className={cls("bootcampCarousel")}>
      <div className={cls("conatiner")}>
        <Slider ref={sliderRef} settings={settings}>
          {bootcamps.slice(0,10)?.map((bootcamp) => (
            <BootcampCard key={bootcamp?.id} {...bootcamp} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default BootcampCarousel;
