import React from "react";
import dynamic from "next/dynamic";

const Button = dynamic(() => import("@/components/Atoms/Button"), {
  ssr: false,
});

import { clsx } from "@/utils";

interface SlickArrowButtonProps {
  buttonType: "prev" | "next";
  currentSlide?: number;
  slideCount?: number;
  children?: React.ReactNode;
  className?: string;
  variant?: string;
  icon?: string;
}

const SlickArrowButton: React.FC<SlickArrowButtonProps> = ({
  buttonType,
  currentSlide,
  slideCount,
  className,
  ...props
}) => {
  let disabled = false;
  if (buttonType === "prev") {
    disabled = currentSlide === 0;
  } else {
    disabled = currentSlide === slideCount - 1;
  }

  return (
    <Button
      className={clsx(className, disabled ? " slick-disabled" : "")}
      aria-hidden="true"
      aria-disabled={disabled ? true : false}
      {...props}
    />
  );
};

export default SlickArrowButton;
