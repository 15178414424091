import Typography from "@/foundation/Typography/V2";
import { classNameGenerator, handleScrollIntoView } from "@/utils";
import { useAppSelector, useWindowDimensions } from "@/hooks";
import styles from "./CustomerReview.module.scss";
import { Button, SVGSprite } from "@/components/Atoms";
import TestimonialCarousel from "./TestimonialCarousel";
import { useEffect, useState } from "react";

interface CustomerReviewProps { }

const CustomerReview: React.FC<CustomerReviewProps> = () => {
    const cls = classNameGenerator(styles);
    const { data } = useAppSelector((state) => state.healthProgramV1);
    const { width } = useWindowDimensions()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setIsMobile(width < 600)
    }, [width])

    if (!data?.customerReview) {
        return <></>;
    }

    const { title, description, metrics, ctaText, reviews } = data?.customerReview || {};

    return (
        <section className={cls("bootcampListing")}>
            <div className={cls("conatiner")}>
                <div className={cls("heading")}>
                    <Typography variant="heading2" className={cls("title")}>
                        {title}
                    </Typography>
                    <Typography variant="paragraph1" className={cls("description")}>
                        {description}
                    </Typography>
                </div>
                <div className={cls("metrics")}>
                    {metrics.map((_val, index) => {
                        return (
                            <div key={index} className={cls("content")}>
                                <Typography variant="heading2" className={cls("title")}
                                    dangerouslySetInnerHTML={{ __html: isMobile ? _val?.mobileTitle : _val?.title }}
                                />
                                <Typography variant="paragraph1" className={cls("description")}
                                    dangerouslySetInnerHTML={{ __html: _val?.description }}
                                />
                            </div>
                        )
                    })}
                </div>
                <TestimonialCarousel data={reviews} />
                <Button
                    className={cls("actionButton")}
                    onClick={() =>
                        handleScrollIntoView({ id: "service-card" })
                    }
                >
                    {ctaText}
                    <SVGSprite
                        icon="icon-ArrowCircleRight"
                        className={cls("icon")}
                    />
                </Button>
            </div>
        </section>
    );
};

export default CustomerReview;
