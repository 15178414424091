const data = {
    "heroSection": {
        "title": "<span style=\"color:#8E5537\">Protein-o-Meter</span> by Shyft. Calculate your daily Protein needs.",
        "description": "Your protein needs are unique to you, depending on factors like age, activity level, and overall health goals. Use our interactive Proteinometer to calculate your personalized daily protein intake range.",
        "ctaText": "Get started",
        "banner": "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/protein-d6928b498fab4203988947a31c1608d4.png"
    },
    "faqs": {
        "title": "",
        "description": "",
        "faqs": [
          {
            "question": "What does the Proteinometer do?",
            "answer": "Shyft’s Proteinometer gives you a tailored response to “how much Protein should I consume in a day?” Taking into account your body structure, age, activity levels and goals, the proteinometer calculates your body’s requirement for Protein. Knowing your body’s Protein requirement can help you plan your meals & supplements better."
          },
          {
            "question": "Who should use the Proteinometer? Is it only for people who exercise a lot?",
            "answer": "Our Proteinometer can be used by anyone looking to better understand their Protein needs. Whether you are training hard or not at all,simply enter a few details, and it’’ll calculate a range for how much protein your body needs to fuel your lifestyle."
          },
          {
            "question": "What does grams of protein actually look like?",
            "answer": "It’s easy to mistake the weight of whole foods with the amount of protein they contain, but that’s not the case. For reference, here’s a list of common foods and how much protein they contain:<br/><br/>Animal Sources:<br/><ul><li>Chicken breast (31g per 100g)</li><li>Salmon (25g per 100g)</li><li>Eggs (6g per large egg)</li><li>Greek yogurt (10g per 100g)</li></ul><br/>Plant-Based Sources:<br/><ul><li>Lentils (9g per 100g cooked)</li><li>Chickpeas (8g per 100g cooked)</li><li>Quinoa (4g per 100g cooked)</li><li>Tofu (17g per 100g)</li></ul>"
          },
          {
            "question": "How to meet your protein goals?",
            "answer": "Once you’ve calculated your daily protein needs, it’s important to assess if you're hitting your target. Keep track of your protein intake by adding up the grams from each meal and snack. If you're not meeting your goals, consider adding a high-quality protein powder to add to your routine."
          }
        ]
      },
    "quationaries": [
        {
            "question": "What is your current body weight?",
            "description": "The body's protein requirement depends the most on the current weight.",
        },
        {
            "question": "How old are you?",
            "description": "",
            "options": [
              {
                "label":"Upto 18 years",
                "value": "17",
              },
              {
                "label":"18-60 years",
                "value": "20"
              },
              {
                "label":"60 years +",
                "value": "65"
              }
            ]
        },
        {
            "question": "What is your gender?",
            "description": "Protein requirement can vary based on different stages of life across genders.",
            "options": [
              {
                "text":"Male",
                "value": "male",
              },
              {
                "text":"Female",
                "value": "female"
              },
              {
                "text":"Prefer not to disclose",
                "value": "prefer not to disclose"
              }
            ]
        },
        {
            "question": "Are you currently Pregnant or Lactating?",
            "description": "Fetal growth or milk production requires additional daily protein.",
            "options": [
              {
                "text":"Yes",
                "value": true,
              },
              {
                "text":"No",
                "value": false,
              }
            ]
        },
        {
            "question": "How active is your daily life?",
            "description": "Body needs different intake of protein to sustain your level of activity in the day.",
            "options": [
              {
                "text": "Sedentary<br/><span>limited daily movement, sitting most of the day</span>",
                "value": "sedentary"
              },
              {
                "text": "Moderately active<br/><span>walk regularly, do light cardio, or perform basic household chores.</span>",
                "value": "moderately_active"
              },
              {
                "text": "Highly active<br/><span>regular gym-goers, regular athletics or sports, in physically demanding jobs.</span>",
                "value": "highly_active"
              },
              {
                "text": "Very highly active or Strength training<br/><span>focused on heavy weightlifting, bodybuilding, or highly intense physical training like HIIT or endurance athlete.</span>",
                "value": "very_highly_active"
              }
            ]
        }
    ]
}

export default data
