import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";

import Header from "./Header";
import useAuthFlow from "./useAuthFlow";
import useRibbonData from "./useRibbonData";
import MobileDrawer from "./MobileDrawer";

import { useAppSelector } from "@/hooks";

interface NavbarPropsI {
  hideLinks?: boolean;
  showTransparentHeader?: boolean;
  darkHeader?: boolean;
  headerBackgroundColor?: string;
  isStickyHeader?: boolean;
  isLandingPage?: boolean;
  hideRibbon?: boolean;
  appDownloadLink?: string;
  headerRef?: React.Ref<HTMLElement>;
  isShyftPassBookingPage?: boolean;
  windowWidth?: number;
  isHomePage?: boolean;
}

const Navbar: React.FC<NavbarPropsI> = ({
  hideLinks = false,
  hideRibbon,
  isShyftPassBookingPage,
  headerRef,
  showTransparentHeader,
  darkHeader,
  headerBackgroundColor,
  isHomePage,
  ...rest
}) => {
  const authData = useAuthFlow();
  const { showRibbon, ribbonData } = useRibbonData({ hideRibbon });

  const [isTransparentHeader, setIsTransparentHeader] = useState(
    showTransparentHeader
  );

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrollPosition = window.scrollY; // Current vertical scroll position

      if (scrollPosition > 50) {
        setIsTransparentHeader(false)
      } else {
        setIsTransparentHeader(true)
      }
    });
  }, [])
  // useEffect(() => {
  //   if (showTransparentHeader) {
  //     const header = headerRef?.current;
  //     if (header) {
  //       const observer = new IntersectionObserver(
  //         ([e]) => {
  //           console.log("IntersectionObserver")
  //           // if (e.intersectionRatio < 1 && isTransparentHeader) {
  //           //   console.log(e.intersectionRatio, isTransparentHeader, "isTransparentHeader")
  //           //   setIsTransparentHeader(false)
  //           // } else if (e.intersectionRatio >= 1 && !isTransparentHeader) {
  //           //   setIsTransparentHeader(true)
  //           // }
  //           setIsTransparentHeader(e.intersectionRatio < 1 ? false : true);
  //         },
  //         { threshold: [1] }
  //       );
  //       observer.observe(header);
  //     }
  //   }
  // }, [headerRef, showTransparentHeader]);

  const { countryCodeData: countryCodes } = useAppSelector(
    (state) => state.dashboard
  );

  const [showSidebar, setShowSidebar] = useState(false);

  const headerProps = {
    hideLinks,
    showSidebar,
    setShowSidebar,
    showRibbon,
    ribbonData,
    countryCodes,
    authData,
    ...rest,
  };

  const modals = (
    <AnimatePresence>
      {showSidebar && (
        <MobileDrawer
          onClose={() => setShowSidebar(false)}
          token={authData.token}
          logout={() => authData.logout()}
          {...headerProps}
        />
      )}
    </AnimatePresence>
  );

  return (
    <>
      <Header
        {...headerProps}
        headerRef={headerRef}
        darkHeader={isHomePage && darkHeader && isTransparentHeader}
        hideShadow={isTransparentHeader}
        headerBackgroundColor={
          headerBackgroundColor ||
          ((isHomePage && isTransparentHeader) ? "transparent" : "#fff")
        }
        isHomePage={isHomePage}
      />
      {modals}
    </>
  );
};

export default Navbar;
