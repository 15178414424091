export default {
  meta: {
    siteName: "Shyft",
    siteURL: "https://www.betheshyft.com",
    title: "Understanding Protein and Protein Powders",
    description: `Understanding Protein and Protein Powders `,
    thumbnail:
      "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/fcde12e8acec420c95f4b039ef2f6ad3_1-a8ea162ff4e94fe1a1c2d0da775d6c93.png",
  },
  banner: {
    heading: "Understanding Protein and Protein Powders",
    image:
      "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/fcde12e8acec420c95f4b039ef2f6ad3_1-a8ea162ff4e94fe1a1c2d0da775d6c93.png",
  },
  content: {
    qna: [
      {
        question: "Why does the body need protein?",
        answer: `Proteins are the building blocks of life, quite literally. Made up of amino acids, they’re responsible for building and repairing tissues, making enzymes and hormones, and supporting immune function. Every cell in our body has protein, making it essential for health and survival. 
            <br />
            <br />
When you consume protein, your body breaks it down into amino acids, which are then used to repair and build tissues, among other vital functions. Without enough protein, your body can’t perform optimally—whether you’re recovering from exercise, maintaining muscle mass, or simply keeping your immune system healthy.`,
      },
      {
        question: "Why Protein Powders?",
        answer: `While whole foods are the best sources of protein, getting enough protein from just your diet can be challenging. In fact, 9 out of 10 Indians have a diet that’s deficient in proteins (source: Protein Consumption in Diet of Adult Indians : A General Consumer Survey (PRODIGY). 
            <br />
            <br />
Protein powders are a convenient way to supplement your daily intake, helping your body get the fuel it needs to build and repair muscle tissue. However, it’s important to choose high-quality protein supplements and use them to complement, not replace, a balanced diet. `,
        id:"understand-protein"
      },
      {
        question: `What are the typical ingredients in a protein powder? And what ingredients should I watch out for?`,
        answer: `<span>A good protein powder will typically contain:</span>
            <br />
            <ul>
              <li>A source of protein (whey, casein, pea, soy, etc.),</li>
              <li>flavorings and/or sweeteners,</li>
              <li>and sometimes added vitamins or minerals.</li>
            </ul>
            <br />
            However, not all ingredients are created equal. Watch out for
            products loaded with artificial flavors, sweeteners, and
            preservatives, which can cause digestive issues or unnecessary
            strain on your system.
            <br />
            <br />
            <span>Ingredients to watch out for: </span>
            <br />
            <ul>
              <li>Artificial sweeteners (aspartame, sucralose)</li>
              <li>Fillers and thickeners (maltodextrin, xanthan gum)</li>
              <li>
                Preservatives and emulsifiers, which can affect digestion and
                overall health
              </li>
            </ul>`,
            id:"understand-ingredients"
      },
      {
        question: "How to read Protein Powder labels?",
        answer: `<span>When evaluating a protein powder, check the following:</span>
            <ul>
              <li><span>Protein and BCAA content per serving:</span> The higher the protein & BCAA content, the more efficient the product.</li>
              <li><span>Added ingredients:</span> Avoid products with unnecessary fillers like stabilizers, artificial flavours, additives, sweeteners. Remember that <i>'nature identical'</i> is not natural, it is artificial.</li>
              <li><span>Carbs and Calories:</span> Protein is generally good calories so you don't need to watch calories, it is better to watch out for carb content in the protein. Lower carbs are better.</li>
              <li><span>Certifications:</span> Look for third-party testing certifications to verify quality.</li>
            </ul>`,
      },
      {
        question: "How to decide which Protein Powder is right for you?",
        answer: `Your ideal protein powder depends on your dietary needs, fitness goals, and sensitivities. For example, if you’re looking for a protein powder purely for post-workout recovery and have built up a good tolerance to protein powders in the past, a whey protein might be the right fit. However, if you’re newer to protein powders and want a slower absorption that matches with your lifestyle, a plant based protein powder is more suitable for you. Ultimately, it’s important to choose a product that aligns with your values and nutritional requirements.`,
      },
    ],

    tables: {
      table_1: {
        question: "Common sources of protein in protein powders",
        data: [
          {
            name: "Whey Protein",
            description: `A byproduct of milk, currently the most commonly used in protein powders`,
            pros: "Complete protein, fast absorption, promotes muscle recovery",
            cons: `May cause digestive issues, not suitable for
                          vegans/lactose-intolerance`,
          },
          {
            name: "Casein Protein",
            description: `It completes other plant proteins like Pea protein very well and can promote heart health`,
            pros: "Good for muscle maintenance, rich in glutamine",
            cons: `Slow absorption, not ideal for post-workout, not suitable for vegans/lactose-intolerance`,
          },
          {
            name: "Pea Protein",
            description: `Protein derived from yellow peas`,
            pros: "Plant-based, hypoallergenic, high in iron, promotes heart health, more easily digestible plant protein ",
            cons: `Lower in essential amino acids compared to animal-based proteins`,
          },
          {
            name: "Soy Protein",
            description: `Protein derived from soy`,
            pros: "COmplete plant protein, can promote heart health",
            cons: `Contains phytoestrogens which might cause hormonal issues, may cause digestive issues`,
          },
          {
            name: "Brown Rice Protein",
            description: `Protein derived from brown rice`,
            pros: "Plant protein, can promote heart health",
            cons: `Incomplete protein, needs to be combined with other sources for a full amino profile.`,
          },
        ],
      },
      table_2: {
        question: "Common sweetening agents in protein powders",
        data: [
          {
            name: "Stevia",
            description: `A natural sugar substitute made from the plant Stevia rebaudiana`,
            pros: "Natural, zero calories, doesn't cause blood sugar spikes. Usage well established and approved by most authorities including the EU",
            cons: `Distinct bitter aftertaste. Consumption in large amounts can cause digestive issues due to additives like erythritol. Stevia used by Shyft does not have Erythritol`,
          },
          {
            name: "Sucralose",
            description: `An artificial sweetener made by altering sugar through a chemical process`,
            pros: "Zero calories, highly sweet, heat-stable",
            cons: `Artificial, can cause digestive issues, research on long-term consumption is mixed`,
          },
          {
            name: "Aspartame",
            description: `Another artificial sweetener widely used in commercial ‘diet’ drinks`,
            pros: "Zero calories, long shelf life",
            cons: `Artificial, research is still ongoing on its carcinogenic properties`,
          },
          {
            name: "Monk Fruit",
            description: `Natural sweetener extracted from the monk fruit, a small, round fruit commonly found in Southeast Asia`,
            pros: "Natural, zero calories, can have antioxidant properties",
            cons: `Has a distinct flavour, less widely available, relatively new in commercial use and doesn’t have enough research on its consumption. Usage is not yet approved in the EU.`,
          },
          {
            name: "Sugar Alcohols (e.g. Erythritol)",
            description: `Added to foods to sweeten them but lower the negative impacts of sugar. While some sugar alcohols are naturally occurring, the kinds used to sweeten foods are industrially produced `,
            pros: "Lower in calories and lower insulin spike than natural sugar",
            cons: `Can cause digestive discomfort. Recent research connecting erythritol to carcinogenic properties has emerged.`,
          },
          {
            name: "Cane Sugar",
            description: `Natural sugar derived from sugarcane`,
            pros: "Natural, enhances flavor",
            cons: `High in calories, spikes blood sugar, can lead to weight gain`,
          },
        ],
      },
    },
    proteinometer: {
      body: `Unsure about how much protein you really need?
              <span>Head to our Protein-o-meter to find out!</span>`,
    },
  },
};
