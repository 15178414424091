import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";

import PopoverComp from "@/components/Atoms/Popover";
import SVGSprite from "@/components/Atoms/SVGSprite";
import Toast from "@/components/Molecules/Toast";
import LoginFlow from "./LoginFlow";

import { clsx, getCookieStorage } from "@/utils";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { profileMenu } from "@/constants/HeaderMenu";

import * as actions from "@/redux/ProfileReducer";

import { NavigationLink, ProfileButton } from "./style";
import styles from "../NavMenu/NavMenu.module.scss";

interface NavLinkProps {
  link: string;
  category: string;
  isExternal?: boolean;
  isLogoutLink?: boolean;
  icon?: string;
  logout?: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({
  link,
  category,
  isExternal,
  isLogoutLink,
  logout,
}) => {
  return (
    <NavigationLink className="navigationMenuItem">
      {isLogoutLink ? (
        <div onClick={logout} className="navigationMenuLink">
          {category}
        </div>
      ) : isExternal ? (
        <a
          className="navigationMenuLink"
          href={link?.endsWith("/") ? link : `${link}/`}
        >
          {category}
        </a>
      ) : (
        <Link
          className="navigationMenuLink"
          href={link?.endsWith("/") ? link : `${link}/`}
        >
          {category}
        </Link>
      )}
    </NavigationLink>
  );
};

const Authentication = ({ authData , darkHeader }) => {
  const dispatch = useAppDispatch();

  const {
    login,
    token,
    setToken,
    loginModal,
    setLoginModal,
    showToast,
    setShowToast,
    logout,
  } = authData || {};

  const profilePicture = getCookieStorage("__profilePicture");

  useEffect(() => {
    if (
      getCookieStorage("__authAccessToken") &&
      !profilePicture &&
      !window.location.pathname.includes("/profile")
    ) {
      dispatch(actions.getProfileDetails());
    }
  }, [dispatch, profilePicture]);

  const { countryCodeData: countryCodes } = useAppSelector(
    (state) => state.dashboard
  );
  const { data } = useAppSelector((state) => state.profile);

  const profilePic = data?.profile_detail?.profile_pic || profilePicture;

  const profileButton = login ? (
    <NavigationMenu.Root className={styles.navigationMenuRoot}>
      <NavigationMenu.List className={styles.navigationMenuList}>
        <NavigationMenu.Item
          className={clsx(styles.navigationMenuItem, styles.profile)}
        >
          <NavigationMenu.Trigger
            className={styles.navigationMenuTrigger}
            asChild
          >
            <div>
              <ProfileButton
                variant="primary1"
                // onClick={() => (window.location.href = "/profile")}
                version="v2"
                id="profile-btn"
              >
                {profilePic !== "null" ? (
                  <Image
                    src={profilePic}
                    alt="profile"
                    height="40"
                    width="40"
                  />
                ) : (
                  <SVGSprite icon={darkHeader ? "icon-profilewhite" : "icon-user-profile"} />
                )}
              </ProfileButton>
            </div>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className={styles.navigationMenuContent}>
            <ul className={styles.subNavigationMenu}>
              {profileMenu?.map((cat, index) => (
                <NavLink key={index} {...cat} logout={logout} />
              ))}
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>
      </NavigationMenu.List>
    </NavigationMenu.Root>
  ) : (
    <ProfileButton
      variant="primary1"
      onClick={() => setLoginModal(true)}
      onMouseEnter={() => setLoginModal(true)}
      version="v2"
      id="login-btn"
      suppressHydrationWarning
    >
      <SVGSprite icon={darkHeader ? "icon-profilewhite" : "icon-user-profile"} />
    </ProfileButton>
  );

  return (
    <>
      {profileButton}
      <AnimatePresence>
        {!login && loginModal && (
          <LoginFlow
            onClose={() => setLoginModal(false)}
            setLoginToken={setToken}
            countryCodes={countryCodes}
            setShowToast={setShowToast}
            token={token}
          />
        )}
        {showToast && (
          <Toast
            text={showToast?.message}
            autoDismiss
            type={showToast?.type}
            duration={showToast?.duration}
            onClose={() => {
              setShowToast(false);
              localStorage.removeItem("LOGIN_TOAST_DATA");
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Authentication;
