import { combineReducers } from "@reduxjs/toolkit";

import AuthReducer from "./AuthReducer";
import DashboardReducer from "./DashboardReducer";
import healthProgramV1Reducer from "./HealthProgramV1Reducer";
import LandingPageReducer from "./LandingPageReducer";
import YogaProgramReducer from "./YogaProgramReducer";
import HealthProgramPagesReducer from "./HealthProgramReducer";
import ShyftPassReducer from "./ShyftPassReducer";
import CommunityReducer from "./CommunityReducer";
import RecipeReducer from "./RecipeReducer";
import ProfileReducer from "./ProfileReducer";
import HealthSurveyReducer from "./HealthSurveyReducer";
import WebinarReducer from "./WebinarReducer";
import GoalListReducer from "./GoalListReducer";
import AmbassadorReducer from "./AmbassadorReducer";
import unlimitedYogaFlowReducer from "./unlimitedYogaFlowReducer";
import partnershipPageReducer from "./PartnershipPageReducer";
import CareersPageReducer from "./CareersPageReducer";
import AnnualMembershipAndDoctorsCareBenefitReducer from "./AnnualMembershipAndDoctorsCareBenefitReducer";
import trialClassReducer from "./TrialClassReducer";
import doctorLandingPageReducer from "./DoctorLandingPage";
import DiagnosticReducer from "./DiagnosticReducer";
import YogaFaqsReducer from "./FaqsReducer";
import BootCampReducer from "./BootCampReducer";
import ProteinometerPageReducer from "./ProteinometerReducer" 
import UnderstandingProtein from "./UnderstandingProteinReducer";
import AiChatbot from "./AiChatbotReducer"
import ChooseYourMove from "./ChooseYourMoveReducer"

const combinedReducer = combineReducers({
  auth: AuthReducer,
  dashboard: DashboardReducer,
  healthProgramV1: healthProgramV1Reducer,
  landingPage: LandingPageReducer,
  yogaProgram: YogaProgramReducer,
  healthProgram: HealthProgramPagesReducer,
  shyftPass: ShyftPassReducer,
  community: CommunityReducer,
  recipe: RecipeReducer,
  profile: ProfileReducer,
  healthSurvey: HealthSurveyReducer,
  webinar: WebinarReducer,
  nutritionGoalList: GoalListReducer,
  ambassador: AmbassadorReducer,
  unlimitedYogaFlow: unlimitedYogaFlowReducer,
  partnershipPage: partnershipPageReducer,
  careersPage: CareersPageReducer,
  membershipBenefitData: AnnualMembershipAndDoctorsCareBenefitReducer,
  trialClass: trialClassReducer,
  doctorsLandingPage: doctorLandingPageReducer,
  diagnosticPage: DiagnosticReducer,
  yogaFaqsReducer: YogaFaqsReducer,
  bootCamp: BootCampReducer,
  aiChatbot: AiChatbot,
  proteinometer: ProteinometerPageReducer,
  understandingProtein: UnderstandingProtein,
  chooseYourMove: ChooseYourMove
});

export default combinedReducer;
