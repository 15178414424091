import React from "react";
import { useRouter } from "next/router";

import { FlexDiv, DesktopContainer } from "@/components/Atoms/Box";
import Button from "@/components/Atoms/Button";
import NavMenu from "./NavMenu";
import Ribbon, { RibbonPropsI } from "./Ribbon";
import Authentication from "./Authentication";

import { generateQueryArguments } from "@/utils";
import Typography from "@/foundation/Typography/V2";
import theme from "@/foundation/Theme";

import {
  HeaderWrapper,
  HeaderContainer,
  MenuWrapper,
  ShyftLogo,
  LoginWrapper,
  MenuButton,
  ShyftAppButton,
  JoinCommunityButton,
  CloseButton,
  CloseIcon,
  MenuIcon,
} from "./style";

interface HeaderPropsI {
  headerRef?: React.Ref<HTMLElement>;
  headerBackgroundColor?: string;
  hideLinks?: boolean;
  isStickyHeader?: boolean;
  isLandingPage?: boolean;
  darkHeader?: boolean;
  showSidebar?: boolean;
  setShowSidebar?: (val: boolean) => void;
  appDownloadLink?: string;
  showRibbon?: boolean;
  ribbonData?: RibbonPropsI;
  countryCodes?: any[];
  authData?: any;
  hideShadow?: boolean;
  isHomePage?: boolean;
}

const Header: React.FC<HeaderPropsI> = ({
  headerRef,
  headerBackgroundColor,
  hideLinks,
  isStickyHeader,
  isLandingPage,
  darkHeader,
  showSidebar,
  setShowSidebar = () => {},
  appDownloadLink = "",
  showRibbon,
  ribbonData = {},
  countryCodes,
  authData,
  hideShadow,
  isHomePage = false
}) => {
  const router = useRouter();

  let boxShadow = "none";
  if (!hideLinks && !darkHeader && !hideShadow)
    boxShadow = `0px 13px 18px ${theme.colors.link2Color}0a`;

  const logo = (
    <Typography variant="routelink2" to="/">
      <ShyftLogo
        icon="icon-brand-logo-light"
        fill={darkHeader ? theme.colors.white : theme.colors.brand_logo}
        className={(hideShadow && isHomePage) ? "homepagelogo" : ""}
      />
    </Typography>
  );

  const menuItems = !hideLinks && (
    <DesktopContainer>
      <NavMenu isDarkMode={darkHeader} />
    </DesktopContainer>
  );

  const mobileMenu = showSidebar ? (
    <CloseButton
      variant="primary1"
      onClick={() => setShowSidebar(false)}
      version="v2"
    >
      <CloseIcon
        icon="icon-close"
        fill={darkHeader ? theme.colors.white : theme.colors.black}
      />
    </CloseButton>
  ) : (
    <MenuButton
      variant="primary1"
      onClick={() => setShowSidebar(true)}
      version="v2"
    >
      <MenuIcon
        icon="icon-header-menu"
        fill={darkHeader ? theme.colors.white : theme.colors.black}
      />
    </MenuButton>
  );

  const shyftAppButton = (
    <ShyftAppButton
      href={`https://get.betheshyft.com/download-shyft-app`}
      target="_blank"
      id="shyft-app-header-button"
      darkheader={darkHeader ? 1 : 0}
    >
      Download Shyft App
    </ShyftAppButton>
  );

  const joinCommunityButton = (
    <JoinCommunityButton
      href={`/joincommunity/${generateQueryArguments({
        utm_source: "shyft_website",
        utm_medium: "header-button",
        adset_name: "top_navbar",
      })}`}
      id="join-community-header-button"
    >
      Join Community
    </JoinCommunityButton>
  );

  const profileButton = (
    <DesktopContainer>
      <Authentication authData={authData} darkHeader={darkHeader}/>
    </DesktopContainer>
  );

  const appDownloadButton = appDownloadLink && (
    <Button
      variant="primary1"
      onClick={() => window.open(appDownloadLink, "_blank")}
      TextColor={theme.colors.white}
      bgColor={theme.colorsV2.shyft.purple_text}
      borderColor={theme.colorsV2.shyft.purple_text}
      hoverColor={theme.colorsV2.shyft.purple_text}
      borderRadius="12px"
      fontSize="12px"
      lineHeight="20px"
      btnPadding="8px 16px"
    >
      Download app
    </Button>
  );

  return (
    <HeaderWrapper
      ref={headerRef}
      backgroundColor={headerBackgroundColor}
      boxShadow={boxShadow}
      className={isStickyHeader ? (hideShadow && isHomePage) ? "homepageHeader" : "sticky" : ""}
      id="navbar"
    >
      <HeaderContainer
        className={isStickyHeader ? (hideShadow && isHomePage) ? "headerContainer" : "" : ""}
      >
        <MenuWrapper>
          {logo}
          {menuItems}
        </MenuWrapper>
        <FlexDiv alignItems="center">
          {!hideLinks && (
            <>
              <LoginWrapper>
                {shyftAppButton}
                {/* {joinCommunityButton} */}
                {profileButton}
                {mobileMenu}
              </LoginWrapper>
            </>
          )}
          {appDownloadButton}
        </FlexDiv>
      </HeaderContainer>
      {showRibbon && <Ribbon {...ribbonData} />}
    </HeaderWrapper>
  );
};

export default Header;
