import Typography from "@/foundation/Typography/V2";

import BootcampCarousel from "./BootcampCarousel";

import { classNameGenerator } from "@/utils";

import { useAppSelector } from "@/hooks";

import styles from "./BootcampListing.module.scss";
import { useEffect, useState } from "react";
import { Button, SVGSprite } from "@/components/Atoms";
import { useRouter } from "next/router";
import axios from "axios";


interface BootcampListingProps { }

const BlogSection: React.FC<BootcampListingProps> = () => {
  const cls = classNameGenerator(styles);
  const [blogs, setBlogs] = useState([])
  const { data } = useAppSelector((state) => state.healthProgramV1);
  const router = useRouter()
  const { blogs: blogsData } = data || {};
  const { title, description, bottomCta } = blogsData || {};

  if (blogsData?.length === 0) {
    return <></>;
  }

  useEffect(() => {
    getBlogsList()
  }, [])

  const getBlogsList = async () => {
    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/health-program/wordpress/blogs/`)
      if (response.status === 200) {
        setBlogs(response?.data?.data)
      }
    } catch (err) {
      console.error("Something went wrong!")
    }
  }

  return (
    <section className={cls("bootcampListing")}>
      <div className={cls("conatiner")}>
        <div className={cls("heading")}>
          <Typography variant="heading2" className={cls("title")}>
            {title}
          </Typography>
          <Typography variant="paragraph1" className={cls("description")}>
            {description}
          </Typography>
        </div>
        <div className={cls("content")}>
          <BootcampCarousel bootcamps={blogs} />
        </div>
        <Button
          className={cls("actionButton")}
          onClick={() =>
            window.open(`${process.env.NEXT_PUBLIC_WEBSITE_URL}healthwatch/`,"_blank")
          }
        >
          <SVGSprite
            icon={bottomCta?.icon}
            className={cls("icon")}
          />
          {bottomCta?.text}
        </Button>
      </div>
    </section>
  );
};

export default BlogSection;
