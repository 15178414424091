import { useAppSelector } from "@/hooks";
import { classNameGenerator } from "@/utils";
import styles from "./MarqueStripe.module.scss"
import Typography from "@/foundation/Typography/V2";

const MarqueStripe = () => {
    const cls = classNameGenerator(styles)
    const { data } = useAppSelector((state) => state.healthProgramV1);
    if (!data?.stripe) {
        return <></>
    }
    const { stripe } = data

    return (
        <div className={cls("marquee-container")}>
            <div className={cls("marquee")}>
                {
                    stripe.concat(stripe,stripe, stripe,stripe,stripe).map((_val, index) => {
                        return (
                            <>
                                <span className={cls("dot")}>•</span>
                                <Typography
                                    className={cls("text")}
                                    variant={_val?.link ? "link1" : "paragraph1"}
                                    {...(_val?.link ? { href: _val?.link } : {})}
                                >
                                    {_val?.text}
                                </Typography>
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MarqueStripe;