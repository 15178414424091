import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios, timezone as tz } from "@/utils";

import pageData from "./pageData";

const _timezone = tz || "Asia/Calcutta";

export const getUnderstandingProteinPage = createAsyncThunk(
  "getUnderstandingProteinPage",
  ({ slug, ignoreTimezone, timezone }: Record<string, string>) => {
    if (slug === "homepage") {
      return Promise.resolve(pageData);
    } else {
      let url = `${
        process.env.NEXT_PUBLIC_LOCAL_API_URL
      }api/v1/health-program/landingpage/${slug}/${
        ignoreTimezone ? "" : `?timezone=${timezone || _timezone}`
      }`;

      return axios.get(url);
    }
  }
);
