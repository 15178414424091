import { createSlice } from "@reduxjs/toolkit";

import * as actions from "./Actions";

const initialState = {
  isLoading: false,
  error: "",
  success: false,
  data: {},
};

const understandingProteinSlice = createSlice({
  name: "understandingProtein",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actions.getUnderstandingProteinPage.pending, (state) => ({
        ...state,
        isLoading: true,
        success: false,
        error: "",
      }))
      .addCase(
        actions.getUnderstandingProteinPage.fulfilled,
        (state, { payload }) => {
          return {
            ...state,
            isLoading: false,
            success: true,
            data: payload,
          };
        }
      )
      .addCase(
        actions.getUnderstandingProteinPage.rejected,
        (state, { payload }) => ({
          ...state,
          isLoading: false,
          error: payload || "",
        })
      );
  },
});

export { getUnderstandingProteinPage } from "./Actions";

export default understandingProteinSlice.reducer;
