import { classNameGenerator } from "@/utils";
import styles from "./TestimonialCard.module.scss"
import { Image, SVGSprite } from "@/components/Atoms";
import Typography from "@/foundation/Typography/V2";

const TestimonialCard = ({
    text,
    image,
    info,
    age
}:any) => {
    const cls = classNameGenerator(styles)
    return (
        <div className={cls("cardWrapper")}>
            <div className={cls("quoteWrapper")}>
                <SVGSprite
                    icon={"icon-quote1"}
                    className={cls("quoteClass")}
                />
            </div>
            <Typography variant="paragraph1" className={cls("description")}
                dangerouslySetInnerHTML={{ __html: text }}
            />
            <div className={cls("infoWrapper")}>
                <Image
                    src={image}
                    alt={info}
                    className={cls("image")}
                />
                <div className={cls("info")}>
                    <Typography variant="paragraph1" className={cls("name")}
                        dangerouslySetInnerHTML={{ __html: info }}
                    />
                    <Typography variant="paragraph1" className={cls("age")}
                        dangerouslySetInnerHTML={{ __html: age }}
                    />
                </div>
            </div>
        </div>
    )
}


export default TestimonialCard;