import { classNameGenerator } from "@/utils";
import React from "react";
import styles from "./Footer.module.scss";
import { BrandLogo } from "../Footer-old/LeftSection/style";
import { Image, SVGSprite } from "@/components/Atoms";
import Typography from "@/foundation/Typography/V2";
import footerData from "./FooterData";
import { Router, useRouter } from "next/router";

const { company, social, resources } = footerData.categories;
const { appLinks } = footerData.appCard;
const { terms } = footerData;
function Footer() {
  const router = useRouter();
  const cls = classNameGenerator(styles);
  return (
    <div id="footer" className={cls("footer-wrapper")}>
      <div className={cls("footer-socials")}>
        <div className={cls("shyft-icon")}>
          <SVGSprite icon="icon-brand-logo-light" fill="#fff" />
        </div>
        <div className={cls("mid-links")}>
          <div className={cls("mid-top")}>
            {resources.map((i) => {
              return (
                <Typography
                  variant="paragraph1"
                  onClick={() => {
                    if (i?.external) {
                      window.open(i.link, "_blank")
                    } else {
                      router.push(i.link || "")
                    }
                  }}
                >
                  {i.text}
                </Typography>
              );
            })}
          </div>
          <div className={cls("mid-bottom")}>
            {company.map((i) => {
              return (
                <Typography
                  variant="paragraph1"
                  onClick={() => router.push(i.link)}
                >
                  {i.text}
                </Typography>
              );
            })}
          </div>
        </div>
        <div className={cls("social-links")}>
          <div className={cls("sl-top")}>
            {social.map((i) => {
              return (
                <div onClick={() => router.push(i.link)}>
                  {i?.icon ?
                    <SVGSprite icon={i.icon} fill="#fff" />
                    : <Image className={cls("icon-image")} src={i?.image} alt={i?.text} />
                  }
                </div>
              );
            })}
          </div>
          <div className={cls("sl-bottom")}>
            {appLinks.map((i) => (
              <img src={i.icon} onClick={() => router.push(i.link)}></img>
            ))}
          </div>
        </div>
      </div>
      <div className={cls("footer-tncs")}>
        {terms.map((i, idx) => {
          return (
            <>
              <Typography
                variant="paragraph1"
                onClick={() => router.push(i.link || "")}
              >
                {i.text}
              </Typography>
              {idx !== terms.length - 1 ? (
                <Typography variant="paragraph1">|</Typography>
              ) : null}
            </>
          );
        })}
      </div>
    </div>
  );
}

export default Footer;
