import styled from "styled-components";
import Link from "next/link";

import { FlexContainer, FlexDiv, Header } from "@/components/Atoms/Box";
import Button from "@/components/Atoms/Button";
import SVGSprite from "@/components/Atoms/SVGSprite";

export const HeaderWrapper = styled<any>(Header)`
  z-index: 1000;
  background-color: ${({ backgroundColor }) => backgroundColor || "#fff"};
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  &.sticky {
    position: sticky;
    top: -1px;
  }
  &.homepageHeader {
    position: absolute;
    width: 100%;
    top: 58px;
    @media (max-width: 768px) {
      top: 22px;
    }
  }
`;

export const HeaderContainer = styled(FlexContainer)`
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  z-index: 10;
  max-width: 90%;
  @media (max-width: 768px) {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  &.headerContainer{
    @media (max-width: 768px) {
      padding-left: 36px;
      padding-right: 36px;
    }
  }
`;

export const MenuWrapper = styled(FlexDiv)`
  align-items: center;
  gap: 80px;
  @media (max-width: 1400px) {
   gap: 40px;
  }
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ShyftLogo = styled(SVGSprite)`
  width: 76.8px;
  height: 32px;
  @media (max-width: 768px) {
    width: 74.47px;
    height: 31.91px;
  }
  &.homepagelogo{
    width: 105px;
    height: 43.75px;
    @media (max-width: 768px) {
      width: 85px;
      height: 36.49px;
    }
  }
`;

export const LoginWrapper = styled(FlexDiv)`
  gap: 16px;
  align-items: center;
`;

export const CloseButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 32px;
  width: 32px;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  &:hover {
    background-color: #fff;
  }
  @media only screen and (min-width: 766px) {
    display: none;
  }
`;
export const CloseIcon = styled(SVGSprite)`
  height: 12px;
  width: 12px;
`;

export const MenuButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 32px;
  width: 32px;
  background-color: transparent;
  border-radius: 40px;
  &:hover {
    background-color: transparent !important;
  }
  @media only screen and (min-width: 766px) {
    display: none;
  }
`;
export const MenuIcon = styled(SVGSprite)`
  height: 32px;
  width: 32px;
`;

const HeaderButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  font-family: var(--man-600);
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.1px;
  padding: 9px 16px;
  border-radius: 40px;
  text-decoration: none;
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const ShyftAppButton = styled<any>(HeaderButton)`
  border: 1px solid #1d2939;
  color: #1d2939;
  background-color: transparent;
  padding: 12px 16px;
  border-radius: 8px;
  &:hover {
    color: #fff;
    background-color: #101828;
    text-decoration: none;
  }
  ${({ darkheader }) =>
    darkheader
      ? `
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    background-color: transparent;
    &:hover {
      color: #1d2939;
      background-color: #fff;
      text-decoration: none;
    }
  `
      : ""};
`;

export const JoinCommunityButton = styled(HeaderButton)`
  border: 1px solid #d3f0ae;
  color: #487b07;
  background-color: #d3f0ae;
  &:hover {
    color: #487b07;
    background-color: #d3f0ae;
    text-decoration: none;
  }
`;
