import Image from "../Image";
import Typography from "@/foundation/Typography/V2";

import { classNameGenerator, clsx } from "@/utils";

import styles from "./Accordion.module.scss";
import SVGSprite from "../SVGSprite";

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
  openAccordionIndex: number | null;
  setOpenAccordionIndex: any;
  currentIndex: number;
}

function Accordion({
  title,
  children,
  className,
  currentIndex,
  openAccordionIndex,
  setOpenAccordionIndex,
}: AccordionProps) {
  const cls = classNameGenerator(styles);

  const isOpen = openAccordionIndex === currentIndex;

  const toggleAccordion = (index) => {
    if (isOpen) {
      setOpenAccordionIndex(null);
    } else {
      setOpenAccordionIndex(index);
    }
  };

  return (
    <div
      className={clsx(cls("accordion"), isOpen ? cls("accordion-open") : "")}
      onClick={() => toggleAccordion(currentIndex)}
    >
      <div className={cls("header")}>
        <Typography variant="heading4" className={cls("title")}>
          {title}
        </Typography>
        <div className={cls("accordion-icon")}>
          <SVGSprite icon={isOpen ? "icon-accordion-opened" : "icon-accordion-closed"} />
        </div>
      </div>
      <div className={cls("content")}>{children}</div>
    </div>
  );
}

export default Accordion;
