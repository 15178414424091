export default {
  data: {
    meta: {
      siteName: "Shyft",
      siteURL: "https://www.betheshyft.com",
      title: "Join our Movement Campaign",
      description: `Our <span>#ChooseYourMove</span> campaign celebrates movement in all
                its forms - whether you’re dancing in your living room, cycling to
                work, or playing your favorite sport. Research shows that movement is
                connected to not just physical but also mental well-being. So no
                matter which move you choose, every one of them is a step towards a
                stronger, and happier, you. `,
      thumbnail:
        "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/Banner_Image-24548b97e20145a1ae2d4e55cfb1c795.png",
    },
    banner: {
      heading: "Join our Movement Campaign",
      banner_body: `Our <span>#ChooseYourMove</span> campaign celebrates movement in all
          its forms - whether you’re dancing in your living room, cycling to
          work, or playing your favorite sport. Research shows that movement is
          connected to not just physical but also mental well-being. So no
          matter which move you choose, every one of them is a step towards a
          stronger, and happier, you. 
          <br></br>
          Tag us with <span>#ChooseYourMove</span> on our socials and we’ll
          feature you and your favourite form of movement.`,
    },
    community: {
      heading: "Meet the community",
      cards: [
        {
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/61d8d1ae6e5e1ec1df4b1dbe2acda199-min-3e0c736b141c415fb9ec82751360184.png",
          front: {
            heading: "“Finally started my protein journey”",
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
          back: {
            heading: `"I've tried countless protein powders, but this natural one stands out! Pure ingredients I can actually pronounce, no artificial additives, and it blends smoothly with zero chalky aftertaste. Best of all, I'm seeing real results in my muscle definition. Finally, a clean protein that delivers what it promises!"`,
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
        },
        {
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/addcc8dd062f99fb34930f5850820483_1-fd247c804e594dff8ffaf44d5dfe652c.png",
          front: {
            heading: "“Finally started my protein journey”",
            member_name: "Aditya Kumar",
            member_designation: "Shyft Community Member",
          },
          back: {
            heading: `"I've tried countless protein powders, but this natural one stands out! Pure ingredients I can actually pronounce, no artificial additives, and it blends smoothly with zero chalky aftertaste. Best of all, I'm seeing real results in my muscle definition. Finally, a clean protein that delivers what it promises!"`,
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
        },
        {
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/123f69f6f532ab92c1de7833bc957f75_1-f2df9bcea85c423c822c3bd45b9d06db.png",
          front: {
            heading: "“Finally started my protein journey”",
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
          back: {
            heading: `"I've tried countless protein powders, but this natural one stands out! Pure ingredients I can actually pronounce, no artificial additives, and it blends smoothly with zero chalky aftertaste. Best of all, I'm seeing real results in my muscle definition. Finally, a clean protein that delivers what it promises!"`,
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
        },
        {
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/8539c7e838c6ddb497dae24328d05a4d_1-a09198becc3d43e9ad795b6b561d0204.png",
          front: {
            heading: "“Finally started my protein journey”",
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
          back: {
            heading: `"I've tried countless protein powders, but this natural one stands out! Pure ingredients I can actually pronounce, no artificial additives, and it blends smoothly with zero chalky aftertaste. Best of all, I'm seeing real results in my muscle definition. Finally, a clean protein that delivers what it promises!"`,
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
        },
        {
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/bd189978bad333274b5374eb2860c818_1-1abf0427cbbb4becb96ff153dcbc4a4c.png",
          front: {
            heading: "“Finally started my protein journey”",
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
          back: {
            heading: `"I've tried countless protein powders, but this natural one stands out! Pure ingredients I can actually pronounce, no artificial additives, and it blends smoothly with zero chalky aftertaste. Best of all, I'm seeing real results in my muscle definition. Finally, a clean protein that delivers what it promises!"`,
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
        },
        {
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/4305684d5210f3690aa138d832caa7cf-min_1-4c4a040c2408415ab909136c8a381.png",
          front: {
            heading: "“Finally started my protein journey”",
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
          back: {
            heading: `"I've tried countless protein powders, but this natural one stands out! Pure ingredients I can actually pronounce, no artificial additives, and it blends smoothly with zero chalky aftertaste. Best of all, I'm seeing real results in my muscle definition. Finally, a clean protein that delivers what it promises!"`,
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
        },
        {
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/ccaab6a131f336d4f56ba6a7c221bc0e_1-7628966fa31c49f084e577adbc1a3fa5.png",
          front: {
            heading: "“Finally started my protein journey”",
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
          back: {
            heading: `"I've tried countless protein powders, but this natural one stands out! Pure ingredients I can actually pronounce, no artificial additives, and it blends smoothly with zero chalky aftertaste. Best of all, I'm seeing real results in my muscle definition. Finally, a clean protein that delivers what it promises!"`,
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
        },
        {
          image:
            "https://d1mxd7n691o8sz.cloudfront.net/static/mindhouse/imagerepo/2024-12/27013ca4bc728480aa2992bdce12d305_1-ed34cad4391146dcbcf2a6fb4e4ba517.png",
          front: {
            heading: "“Finally started my protein journey”",
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
          back: {
            heading: `"I've tried countless protein powders, but this natural one stands out! Pure ingredients I can actually pronounce, no artificial additives, and it blends smoothly with zero chalky aftertaste. Best of all, I'm seeing real results in my muscle definition. Finally, a clean protein that delivers what it promises!"`,
            member_name: "Aisha Sharma",
            member_designation: "Shyft Community Member",
          },
        },
      ],
    },
  },
};
