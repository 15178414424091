import { classNameGenerator } from "@/utils"
import styles from "./HeroSection.module.scss"
import Slider from "react-slick";
import { useAppSelector } from "@/hooks";
import Typography from "@/foundation/Typography/V2";

const HeroSection = () => {
    const cls = classNameGenerator(styles)
    const { data } = useAppSelector((state) => state.healthProgramV1);
    if (!data?.heroSection) {
        return <></>
    }

    const { heroSection } = data || {}
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    const Cards = (_val, index) => {
        return (
            <div
                key={index}
                className={cls("cardWrapper")}
                style={{
                    background: `
                        linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), 
                        url("${_val?.bgImage}")
                    `
                }}

            >
                <Typography variant="heading2" className={cls("title")}
                    dangerouslySetInnerHTML={{ __html: _val?.title }}
                />
                <Typography variant="paragraph1" className={cls("description")}
                    dangerouslySetInnerHTML={{ __html: _val?.description }}
                />
            </div>
        )
    }

    return (
        <section className={cls("heroWrapper")}>
            <div className={cls("heroContainer")}>
                <Slider {...settings}>
                    {
                        heroSection.map((_val, index) => (
                            <Cards {..._val} index={index} />
                        ))
                    }
                </Slider>
            </div>
        </section>
    )
}

export default HeroSection