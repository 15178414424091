import { forwardRef } from "react";
import SliderComponent from "react-slick";

interface SliderProps {
  children: React.ReactNode;
  settings: any;
}

const Slider = forwardRef(({ children, settings }: SliderProps, ref) => {
  return (
    <SliderComponent ref={ref} {...settings}>
      {children}
    </SliderComponent>
  );
});

Slider.displayName = "slider";

export default Slider;
