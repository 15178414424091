import { useAppSelector } from "@/hooks";
import FaqSection from "@/components/Molecules/FaqSection";
import styles from "./FaqSection.module.scss"
import { classNameGenerator } from "@/utils";

const FaqComponent = () => {
    const cls = classNameGenerator(styles);

    const { data } = useAppSelector((state) => state.healthProgramV1);
    const { faqs } = data

    return (
        <div className={cls("faqComponent")}>
            <FaqSection {...faqs}/>
        </div>
    )
}

export default FaqComponent;