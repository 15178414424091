import { createAsyncThunk } from "@reduxjs/toolkit";

import { axios, timezone as tz } from "@/utils";

import homepageData from "./homepageData";

const _timezone = tz || "Asia/Calcutta";

export const getHealthLandingPageV1 = createAsyncThunk(
  "getHealthLandingPageV1",
  ({ slug, ignoreTimezone, timezone }) => {
    if (slug === "homepage") {
      return Promise.resolve(homepageData);
    } else {
      let url = `${
        process.env.NEXT_PUBLIC_LOCAL_API_URL
      }api/v1/health-program/landingpage/${slug}/${
        ignoreTimezone ? "" : `?timezone=${timezone || _timezone}`
      }`;

      return axios.get(url);
    }
  }
);

export const getOneOnOneYogaSlots = createAsyncThunk<
  any,
  { timezone?: string }
>("getOneOnOneYogaSlots", async ({ timezone = "" }) => {
  let slug = "1-on-1-yoga-est-slots";
  if (
    ["US/Pacific", "America/Vancouver", "America/Los_Angeles"]?.includes(
      timezone
    )
  ) {
    slug = "1-on-1-yoga-pst-slots";
  }
  let oneOnOneYogaSlotsURL = `${process.env.NEXT_PUBLIC_LOCAL_API_URL}api/v1/module-header/${slug}/`;
  return await axios.get(oneOnOneYogaSlotsURL);
})
