import { classNameGenerator, handleScrollIntoView } from "@/utils";
import styles from "./ServiceCard.module.scss"
import { Button, Image, SVGSprite } from "@/components/Atoms";
import Typography from "@/foundation/Typography/V2";
import { useRouter } from "next/router";

const ServiceCard = (props) => {
    const router = useRouter()
    const cls = classNameGenerator(styles)
    const { title, description, sideBanner, bgBanner, primaryCta, secondaryCta } = props || {}

    const handleCtaClick = (redirectionType, link) => {
        if (redirectionType === 'id') {
            handleScrollIntoView({ id: link })
        } else {
            router.push(link)
        }
    }

    return (
        <div
            style={{
                background: `linear-gradient(270deg, rgba(0, 0, 0, 0) -3.44%, rgba(0, 0, 0, 0.5) 84.48%), url("${bgBanner}")`
            }}
            className={cls("cardContainer")}
        >
            <div className={cls("leftSide")}>
                <Typography variant="heading3" className={cls("title")}>
                    {title}
                </Typography>
                <Typography
                    variant="paragraph1"
                    className={cls("description")}
                    font-family="var(--dm-sans)"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                <div className={cls("buttonWrapper")}>
                    {primaryCta && <Button
                        className={cls("primaryCta")}
                        onClick={() => {
                            if (primaryCta?.id) {
                                handleCtaClick("id", primaryCta?.id)
                            } else if (primaryCta?.href) {
                                handleCtaClick("link", primaryCta?.href)
                            }
                        }}
                    >
                        {primaryCta?.text}
                        <SVGSprite
                            icon={primaryCta?.icon}
                            className={cls("icon")}
                        />
                    </Button>
                    }
                    {secondaryCta && <Button
                        className={cls("secondaryCta")}
                        onClick={() => {
                            if (secondaryCta?.id) {
                                handleCtaClick("id", secondaryCta?.id)
                            } else if (secondaryCta?.href) {
                                handleCtaClick("link", secondaryCta?.href)
                            }
                        }}
                    >
                        {secondaryCta?.text}
                        <SVGSprite
                            icon={secondaryCta?.icon}
                            className={cls("icon")}
                        />
                    </Button>
                    }
                </div>
            </div>
            <div className={cls("rightSide")}>
                <Image
                    alt={title}
                    src={sideBanner}
                    className={cls("cardImage")}
                />
            </div>
        </div>
    )
}

export default ServiceCard;