import { useCallback, useEffect } from "react";

import HomePage from "@/containers/HomePageV2";

import { renderMetaTags } from "@/utils";

//Redux
import { useAppDispatch, useAppSelector } from "@/hooks";
import * as actions from "@/redux/HealthProgramV1Reducer";
import * as dashboardActions from "@/redux/DashboardReducer";
import { wrapper } from "@/redux/Store";
import { appendFreshChatScript } from "../utils/Scripts/index"
const Home = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector((state) => state.healthProgramV1);

  const getHealthLandingPageV1 = useCallback(
    () => dispatch(actions.getHealthLandingPageV1({ slug: "homepage" })),
    [dispatch]
  );

  useEffect(() => {
    getHealthLandingPageV1();
  }, [getHealthLandingPageV1]);

  const metaTags = renderMetaTags(data);

  useEffect(() => {
    appendFreshChatScript()
  },[])

  return (
    <>
      {metaTags}
      <main>
        <HomePage data={data} />
      </main>
    </>
  );
};

export default Home;

export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async () => {
    await store.dispatch(actions.getHealthLandingPageV1({ slug: "homepage" }));
    await store.dispatch(dashboardActions.getRibbonData({ pathname: "" }));
  }
);
