import { createAsyncThunk } from "@reduxjs/toolkit";
import data from "./pageData";

export const getProteinometerPage = createAsyncThunk(
  "getProteinometerPage",
  () => {
    // if (slug === "homepage") {
      return Promise.resolve(data);
    // } else {
    //   let url = `${
    //     process.env.NEXT_PUBLIC_LOCAL_API_URL
    //   }api/v1/health-program/landingpage/${slug}/${
    //     ignoreTimezone ? "" : `?timezone=${timezone || _timezone}`
    //   }`;

    //   return axios.get(url);
    // }
  }
);
