import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import MainTemplate from "@/components/Templates/MainTemplate";
import { useWindowDimensions } from "@/hooks";
import HeroSection from "./HeroSection/index"
import FaqComponent from "./FaqSection";
import BlogSection from "./BlogSection";
import CommunityCard from "./CommunityCard";
import CustomerReview from "./CustomerReview";
import Services from "./Services";
import MarqueStripe from "./MarqueStripe"
import ProductSection from "./ProductSection";

function HomePageNew({ data: homePageData }) {
  const { pathname } = useRouter();
  const { width } = useWindowDimensions();

  let urlParams = "";
  if (typeof window !== "undefined") {
    urlParams = window.location.search;
  }

  const [active, setActive] = useState(1);

  const { data, isLoading, error } = homePageData || {};
  
  useEffect(() => {
    const text1Ref = document.getElementById("text-1");
    const text2Ref = document.getElementById("text-2");
    const text3Ref = document.getElementById("text-3");
    const text4Ref = document.getElementById("text-4");
    const text5Ref = document.getElementById("text-5");

    if (width > 1079) {
      const detectScroll = () => {
        let count = active;
        window.onscroll = () => {
          let windowHeight = window.innerHeight;

          let topMargin =
            windowHeight >= 655 ? Math.round(windowHeight * 0.1) : 0;
          let screenTop = 0 + topMargin;

          let text1 = text1Ref?.getBoundingClientRect();
          let text2 = text2Ref?.getBoundingClientRect();
          let text3 = text3Ref?.getBoundingClientRect();
          let text4 = text4Ref?.getBoundingClientRect();
          let text5 = text5Ref?.getBoundingClientRect();

          if (
            windowHeight > text1?.bottom - windowHeight &&
            screenTop < text1?.top
          ) {
            text1Ref.style.opacity = "1";
            text2Ref.style.opacity = "0";
            text3Ref.style.opacity = "0";
            text4Ref.style.opacity = "0";
            text5Ref.style.opacity = "0";
            count = 1;
            if (active !== count) setActive(count);
          } else if (
            windowHeight > text2?.bottom - windowHeight &&
            screenTop < text2?.top
          ) {
            if (text2?.bottom - windowHeight <= 0) {
              text2Ref.style.opacity = "1";
              count = 2;
              if (active !== count) setActive(count);
            } else {
              text2Ref.style.opacity = "0";
              if (active !== count) setActive(count);
            }
            text1Ref.style.opacity = "0";
            text3Ref.style.opacity = "0";
            text4Ref.style.opacity = "0";
            text5Ref.style.opacity = "0";
          } else if (
            windowHeight > text3?.bottom - windowHeight &&
            screenTop < text3?.top
          ) {
            if (text3?.bottom - windowHeight <= 0) {
              text3Ref.style.opacity = "1";
              count = 3;
              if (active !== count) setActive(count);
            } else {
              text3Ref.style.opacity = "0";
              if (active !== count) setActive(count);
            }
            text1Ref.style.opacity = "0";
            text2Ref.style.opacity = "0";
            text4Ref.style.opacity = "0";
            text5Ref.style.opacity = "0";
          } else if (
            windowHeight > text4?.bottom - windowHeight &&
            screenTop < text4?.top
          ) {
            if (text4?.bottom - windowHeight <= 0) {
              text4Ref.style.opacity = "1";
              count = 4;
              if (active !== count) setActive(count);
            } else {
              text4Ref.style.opacity = "0";
              if (active !== count) setActive(count);
            }
            text1Ref.style.opacity = "0";
            text2Ref.style.opacity = "0";
            text3Ref.style.opacity = "0";
            text5Ref.style.opacity = "0";
          } else if (
            windowHeight > text5?.bottom - windowHeight &&
            screenTop < text5?.top
          ) {
            if (text5?.bottom - windowHeight <= 0) {
              text5Ref.style.opacity = "1";
              count = 5;
              if (active !== count) setActive(count);
            } else {
              text5Ref.style.opacity = "0";
              if (active !== count) setActive(count);
            }
            text1Ref.style.opacity = "0";
            text2Ref.style.opacity = "0";
            text3Ref.style.opacity = "0";
            text4Ref.style.opacity = "0";
          }
        };
      };
      detectScroll();
    }
  });

  return (
    <MainTemplate
      // isLoading={isLoading}
      error={error}
      // hideHeader
      showTransparentHeader
      darkHeader
      isHomePage={true}
    >
        <HeroSection/>
        <MarqueStripe/>
        <ProductSection/>
        <Services/>
        <CustomerReview/>
        <CommunityCard/>
        <BlogSection />
        <FaqComponent />
    </MainTemplate>
  );
}

export default HomePageNew;
