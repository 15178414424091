import { classNameGenerator } from "@/utils";
import styles from "./ProductSection.module.scss"
import Typography from "@/foundation/Typography/V2";
import { useAppSelector } from "@/hooks";
import { Button, Image, SVGSprite } from "@/components/Atoms";
import { useRouter } from "next/router";

const ProductSection = () => {
    const cls = classNameGenerator(styles)
    const { data } = useAppSelector((state) => state.healthProgramV1);
    const router = useRouter()

    if (!data?.products) {
        return <></>
    }

    const { title, description, productDetails, viewReport, detailsSection, cartDetails } = data?.products || {}
    const { proteinometer, image } = productDetails || {}
    const PrimaryCard = (data) => {
        const { itemList, ctaText } = data
        return (
            <div className={cls("cardWrapper")}>
                <div className={cls("contentBox")}>
                    {itemList?.map((_val, index) => {
                        return (
                            <div className={cls("content")}>
                                <SVGSprite
                                    icon={_val?.icon}
                                    className={cls("icon")}
                                />
                                <Typography variant="paragraph1" className={cls("description")}
                                    dangerouslySetInnerHTML={{ __html: _val?.text }}
                                />
                            </div>
                        )
                    })}
                </div>
                <Button
                    className={cls("actionButton")}
                    onClick={() =>
                        router.push("/understand-protein-powders?id=understand-ingredients")
                    }
                >
                    {ctaText}
                </Button>
            </div>
        )
    }

    const SecondaryCard = (data) => {
        const { itemList, ctaText, imageMobile } = data

        return (
            <div className={cls("cardWrapper")}>
                <div className={cls("topSection")}>
                    <Image
                        src={imageMobile}
                        alt="protein-packet"
                        className={cls("productImage")}
                    />
                    <div className={cls("contentBox")}>
                        {itemList?.map((_val, index) => {
                            return (
                                <div className={cls("content")}>
                                    {
                                        _val?.icon && <SVGSprite
                                            icon={_val?.icon}
                                            className={cls("icon")}
                                        />
                                    }
                                    <Typography variant="paragraph1" className={cls("description")}
                                        dangerouslySetInnerHTML={{ __html: _val?.text }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <Button
                    className={cls("actionButton")}
                    onClick={() =>
                        router.push("/understand-protein-powders?id=understand-protein")
                    }
                >
                    {ctaText}
                </Button>
            </div>
        )
    }
    return (
        <section className={cls("productWrapper")}>
            <div className={cls("contianer")}>
                <div className={cls("heading")}>
                    <Typography variant="heading2" className={cls("title")}>
                        {title}
                    </Typography>
                    <Typography variant="paragraph1" className={cls("description")}>
                        {description}
                    </Typography>
                </div>
                <div className={cls("productSection")}>
                    <div className={cls("imageWrapper")}>
                        <div className={cls("viewReport")}>
                            <SVGSprite
                                icon={viewReport?.icon}
                                className={cls("icon")}
                            />
                            <Typography variant="paragraph1" className={cls("description")}
                                dangerouslySetInnerHTML={{ __html: viewReport?.text }}
                            />
                        </div>
                        <div className={cls("leftSide")}>
                            <div className={cls("top")}>
                                {detailsSection[3]?.itemList?.map((_val, index) => {
                                    return (
                                        <div className={cls("content")}>
                                            <div className={cls("iconWrapper")}>
                                                <SVGSprite
                                                    icon={_val?.icon}
                                                    className={cls("icon")}
                                                />
                                            </div>
                                            <Typography variant="paragraph1" className={cls("description")}
                                                dangerouslySetInnerHTML={{ __html: _val?.text }}
                                            />
                                        </div>
                                    )
                                })}
                                <Image
                                    src="/images/arrow1.png"
                                    alt="arrow1"
                                    className={cls("arrow1")}
                                />
                            </div>
                            <div className={cls("bottom")}>
                                {detailsSection[0]?.itemList?.map((_val, index) => {
                                    return (
                                        <div className={cls("content")}>
                                            <div className={cls("iconWrapper")}>
                                                <SVGSprite
                                                    icon={_val?.icon}
                                                    className={cls("icon")}
                                                />
                                            </div>
                                            <Typography variant="paragraph1" className={cls("description")}
                                                dangerouslySetInnerHTML={{ __html: _val?.text }}
                                            />
                                        </div>
                                    )
                                })}
                                <Button
                                    className={cls("actionButton")}
                                    onClick={() =>
                                        router.push("/understand-protein-powders?id=understand-ingredients")
                                    }
                                >
                                    {detailsSection[0]?.ctaText}
                                </Button>
                                <Image
                                    src="/images/arrow2.png"
                                    alt="arrow2"
                                    className={cls("arrow2")}
                                />
                            </div>
                        </div>
                        <div className={cls("rightSide")}>
                            <div className={cls("top")}>
                                {detailsSection[1]?.itemList?.map((_val, index) => {
                                    return (
                                        <div className={cls("content")}>
                                            <Typography variant="paragraph1" className={cls("description")}
                                                dangerouslySetInnerHTML={{ __html: _val?.text || _val?.mobileText }}
                                            />
                                        </div>
                                    )
                                })}
                                <Button
                                    className={cls("actionButton")}
                                    onClick={() =>
                                        router.push("/understand-protein-powders?id=understand-protein")
                                    }
                                >
                                    {detailsSection[1]?.ctaText}
                                </Button>
                                <Image
                                    src="/images/arrow3.png"
                                    alt="arrow3"
                                    className={cls("arrow3")}
                                />
                                <Image
                                    src="/images/protein.png"
                                    alt="protein"
                                    className={cls("protein")}
                                />
                            </div>
                            <div className={cls("bottom")}>
                                {detailsSection[2]?.itemList?.map((_val, index) => {
                                    return (
                                        <div className={cls("content")}>
                                            <Typography variant="paragraph1" className={cls("description")}
                                                dangerouslySetInnerHTML={{ __html: _val?.text || _val?.mobileText }}
                                            />
                                        </div>
                                    )
                                })}
                                <Image
                                    src="/images/arrow4.png"
                                    alt="arrow4"
                                    className={cls("arrow4")}
                                />
                            </div>
                        </div>
                        <Image
                            src={image}
                            alt={title}
                            className={cls("productImage")}
                        />
                        <Image
                            onClick={() => router.push(cartDetails?.link)}
                            src={cartDetails?.image}
                            alt="add to cart"
                            className={cls("addToCart")}
                        />
                        <div className={cls("proteinometerWrapper1")}>
                            <div 
                                className={cls("proteinometer")}
                                onClick={() => router.push('/proteinometer')}
                            >
                                <div className={cls("contentWrapper")}>
                                    <SVGSprite
                                        icon={proteinometer?.icon1}
                                        className={cls("icon")}
                                    />
                                    <Typography variant="paragraph1" className={cls("description")}
                                        dangerouslySetInnerHTML={{ __html: proteinometer?.text }}
                                    />
                                </div>
                                <SVGSprite
                                    icon={proteinometer?.icon2}
                                    className={cls("icon")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={cls("scrollSection")}>
                        {
                            detailsSection.map((_val, index) => {
                                return (
                                    <>
                                        {_val?.cardType === "pre"
                                            ? <PrimaryCard {..._val} />
                                            : <SecondaryCard {..._val} />
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                    <div className={cls("proteinometerWrapper2")}>
                        <div className={cls("proteinometer")}
                            onClick={() => router.push('/proteinometer')}
                        >
                            <div className={cls("contentWrapper")}>
                                <SVGSprite
                                    icon={proteinometer?.icon1}
                                    className={cls("icon")}
                                />
                                <Typography variant="paragraph1" className={cls("description")}
                                    dangerouslySetInnerHTML={{ __html: proteinometer?.text }}
                                />
                            </div>
                            <SVGSprite
                                icon={proteinometer?.icon2}
                                className={cls("icon")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductSection;